<template>
  <div class="dustroll">
    <CompHeader title="Roll" />
    <div class="changebox">
      <div
        class="changeitem"
        v-for="item in changetypelist"
        @click="changeclick(item.id)"
        :key="item.id"
        :class="{ accahngeitem: item.id == actypeindex }"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="center">
      <div class="checklist" v-show="this.actypeindex == 1">
        <div
          class="check"
          v-for="item in checkdata"
          :key="item.id"
          @click="checkclick(item.id)"
        >
          <div class="checkbox">
            <img
              v-show="item.id == checkindex"
              src="../../assets/newImages/check-all.png"
              alt=""
            />
          </div>
          <div class="checkname">{{ item.title }}</div>
        </div>
        <div
          class="isjoinbox"
          @click="isjoinclick"
          :class="{ acisjoinbox: isjoin == 1 }"
        >
          可加入
        </div>
      </div>
      <div class="searchbox" v-show="this.actypeindex == 1">
        <input
          type="text"
          placeholder="请输入想要搜索ROLL房"
          v-model="searchroll"
        />
        <div class="searchenter" @click="searchclick">
          <img src="../../assets/newImages/search-icon.png" alt="" />
        </div>
      </div>

      <div class="roomlist">
        <div class="roomitem" v-for="item in RoomList" :key="item.id">
          <div class="roomcenter">
            <div class="itemtop">
              <div class="lbox">
                <div class="headimg">
                  <img :src="item.user.avatar" alt="" />
                </div>
                <div class="roomtitle">
                  {{ item.name }}
                </div>
              </div>
              <div class="rbox">
                <div class="numinfo">
                  <span>数量</span>
                  <span>{{
                    item.awards_count
                      ? item.awards_count
                      : item.box_records.length
                  }}</span>
                </div>
                <div class="numinfo">
                  <span>玩家</span> <span>{{ item.join_number }}</span>
                </div>
              </div>
            </div>
            <div class="itemcontent">
              <div
                class="goodsitem"
                v-for="vitem in item.awards?.slice(0, 4)
                  ? item.awards?.slice(0, 4)
                  : item?.box_records?.slice(0, 4)"
                :key="vitem.id"
              >
                <img
                  :src="
                    vitem.box_record?.cover
                      ? vitem.box_record?.cover
                      : vitem.cover
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="itemfooter">
              <div class="endtime">开奖时间:{{ item.end_time }}</div>

              <div
                class="nojoinbtnbox"
                v-if="item.status == 1"
                @click="goroom(item.id)"
              >
                已结束
              </div>
              <div class="joinbtnbox" v-else @click="goroom(item.id)">进入</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Rooms, UserRooms } from "@/network/api.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      changetypelist: [
        {
          id: 1,
          title: "进行中",
        },
        {
          id: 2,
          title: "已加入",
        },
        {
          id: 3,
          title: "已结束",
        },
      ],
      actypeindex: 1,
      RoomList: [], //房间列表
      myroom: [], //我的

      searchroll: "", //搜索roll房
      checkdata: [
        {
          id: 1,
          title: "主播",
        },
        {
          id: 0,
          title: "官方",
        },
      ],
      userrolltype: 1,
      checkindex: 1,

      isjoin: null,
    };
  },
  created() {
    this.GetRooms();
  },
  //   watch: {
  //     checkindex(nv) {
  //       console.log(nv, "状态");
  //       if (nv == 1) {
  //         this.GetRooms();
  //       }
  //     },
  //     actypeindex(nv) {
  //       console.log(nv);
  //       if (this.checkindex == 1) {
  //         this.GetRooms();
  //       } else {
  //         if (nv == 0) {
  //           console.log("我的官方");
  //           this.RoomList = this.myroom.filter((v) => {
  //             if (v.type == 0) {
  //               return v;
  //             }
  //           });
  //         } else {
  //           this.RoomList = this.myroom.filter((v) => {
  //             if (v.type == 1) {
  //               return v;
  //             }
  //           });
  //           console.log("我的主播");
  //         }
  //       }
  //     },
  //   },
  methods: {
    isjoinclick() {
      if (this.isjoin == 1) {
        this.isjoin = null;
      } else {
        this.isjoin = 1;
      }
      this.GetRooms();
    },
    searchclick() {
      if (this.searchroll) {
        this.GetRooms();
        this.searchroll = "";
      } else {
        this.GetRooms();
      }
    },
    goroom(v) {
      this.$router.push({
        path: "/RoomDetail",
        query: {
          id: v,
        },
      });
    },
    checkclick(v) {
      this.checkindex = v;
      this.GetRooms();
    },
    changeclick(v) {
      this.actypeindex = v;
      switch (v) {
        case 1:
          this.GetRooms();
          break;
        case 2:
          if (!this.user.id) {
            this.$message({
              message: "认证失败，请重新登录！",
              type: "error",
            });
          } else {
            this.userrolltype = 1;
            this.GetUserRooms();
          }
          break;
        case 3:
          if (!this.user.id) {
            this.$message({
              message: "认证失败，请重新登录！",
              type: "error",
            });
          } else {
            this.userrolltype = 2;
            this.GetUserRooms();
          }
          break;
      }
    },

    //参加房间记录(我参与的)
    GetUserRooms() {
      let params = {
        page: 1,
        type: this.userrolltype,
      };
      UserRooms(params).then((res) => {
        this.RoomList = res.data.data.data;
      });
    },
    //获取房间列表(初始)
    GetRooms() {
      let params = {
        page: 1,
        type: this.checkindex,
      };
      if (this.searchroll != "") {
        params = {
          page: 1,
          type: this.checkindex,
          keywords: this.searchroll,
        };
      }
      if (this.isjoin == 1) {
        params = {
          page: 1,
          type: this.checkindex,
          is_join: 1,
        };
      }
      Rooms(params).then((res) => {
        this.RoomList = res.data.data;
        // this.roomlist0 = this.RoomList.filter((v) => {
        //   if (v.type == 0) {
        //     return v;
        //   }
        // });
        // this.roomlist1 = this.RoomList.filter((v) => {
        //   if (v.type == 1) {
        //     return v;
        //   }
        // });
      });
    },
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.dustroll {
  overflow: hidden;
  padding-top: 0.5rem;
}
.changebox {
  width: 3.5rem;
  height: 0.38rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #161514;
  padding: 0 0.04rem;
  .changeitem {
    width: 0.96rem;
    height: 0.3rem;
    background: url("../../assets/newImages/record-table.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 0.12rem;
    color: #b79f79;
  }
  .accahngeitem {
    background: url("../../assets/newImages/record-actable.png") no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
  }
}
.center {
  width: 3.35rem;
  margin: 0 auto;
  .checklist {
    display: flex;
    align-items: center;
    height: 0.4rem;
    position: relative;
    .check {
      display: flex;
      align-items: center;
      width: 0.56rem;
      height: 100%;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      margin-right: 0.24rem;
      .checkbox {
        width: 0.12rem;
        height: 0.12rem;
        background: #4f4424;
        margin-right: 0.08rem;
        position: relative;
        img {
          position: absolute;
          bottom: 0.005rem;
          width: 0.15rem;
        }
      }
    }
    .isjoinbox {
      position: absolute;
      right: 0;
      width: 1.07rem;
      height: 0.22rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      @include bgImg("price-change-bg");
    }
    .acisjoinbox {
      @include bgImg("dust-mall-actypeitembg");
    }
  }
  .searchbox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.22rem;
    input {
      width: 2.9rem;
      height: 100%;
      border: 0.01rem solid #817043;
      text-decoration: none;
      background: rgba(42, 27, 0, 0.6);
      text-indent: 0.28rem;
      color: #a4a4a4;
      font-size: 0.12rem;
    }
    .searchenter {
      width: 0.5rem;
      height: 0.22rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../assets/newImages/search-btn-bg.png") no-repeat;
      background-size: 100% 100%;
      img {
        width: 0.14rem;
        height: 0.14rem;
      }
    }
  }
  .roomlist {
    width: 100%;
    height: 100%;
    .roomitem {
      width: 3.35rem;
      height: 1.76rem;
      margin-top: 0.12rem;
      @include bgImg("roll-itembg");
      .roomcenter {
        width: 3.15rem;
        margin: 0 auto;
        .itemtop {
          width: 100%;
          height: 0.46rem;
          display: flex;
          align-items: center;
          .lbox {
            width: 60%;
            display: flex;
            align-items: center;
            .headimg {
              width: 0.3rem;
              height: 0.3rem;
              background: url("../../assets/newImages/user-headbg.png")
                no-repeat;
              background-size: 100% 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 0.28rem;
                height: 0.28rem;
                border-radius: 50%;
              }
            }
            .roomtitle {
              width: 80%;
              padding-left: 0.1rem;
            }
          }
          .rbox {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 0.1rem;
            color: #ffffff;
            .numinfo {
              margin-right: 0.1rem;
            }
          }
        }
        .itemcontent {
          width: 100%;
          height: 0.66rem;
          background: rgba(42, 27, 0, 0.8);
          border: 0.01rem solid #817043;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.1rem;
          .goodsitem {
            width: 0.67rem;
            height: 0.5rem;
            border: 0.01rem solid #69552d;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 95%;
              height: 95%;
            }
          }
        }
        .itemfooter {
          width: 100%;
          .endtime {
            font-weight: 400;
            font-size: 0.1rem;
            text-align: center;
            color: #ffffff;
            margin: 0.06rem 0;
          }
          .joinbtnbox {
            width: 100%;
            height: 0.3rem;
            background: url("../../assets/newImages/roll-joinbtnbg.png")
              no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 0.14rem;
            color: #000000;
          }
          .nojoinbtnbox {
            width: 100%;
            height: 0.3rem;
            background: url("../../assets/newImages/roll-nojoinbtnbg.png")
              no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 0.14rem;
            color: #b17a43;
          }
        }
      }
    }
  }
}
</style>
